import { useEffect, useState } from "react"
import { _get, _post } from "../../../../apiClient"
import LoaderComp from "../../../loader/Loader"
import { useIsMatchingRealTimeResult } from "../../../context/isMatchingRealTimeResult"
import IQG from "../Match/IQG/IQG"
import EmailCandidate from "../Match/Email/Email"
import './RealTimeMatch.css'
import EmailJob from "../../ReverseMatching/Match/Email/EmailJob"
import CardDetailsRealTime from "./CardDetailsReal/CardDetailsRealTime"
import { useMsal } from "@azure/msal-react"

const RealTimeMatch = (props: any) => {
    const [candidates, setCandidates] = useState<any>([])
    const [tempString, setTempString] = useState<any>("")
    const [emailC, setEmailC] = useState(false);
    const { toggleView } = useIsMatchingRealTimeResult();
    const [isDetails, setDetails] = useState(false);
    const [iqg, setIqg] = useState(false);
    const [email, setEmail] = useState(false);
    const [codFisc, setCodFisc] = useState("");
    const [candidateDetails, setCandidateDetails] = useState({});
    const [cvs, setCvs] = useState("")
    const [timeE, setTimeE] = useState(false)
    const [matchDetails, setMatchDetails] = useState({});
    const [headersNew, setHeadersNew] = useState<any>({})
    const [explain, setExplain] = useState<any>({})
    const [dropdown, setDropdown] = useState(null);
    const [urlPDF, setUrlPDF] = useState("");
    const [isAI, setIsAI] = useState(false)
    const [candidateId, setCandidateId] = useState("")

    const { accounts } = useMsal();

    const getBack = () => {
        toggleView(false);
      }
    const handleSearchCandidate = async() => {
        let headersTemp:any = {}
        let expTemp:any = {}
        props.headers.forEach((value:any) => {
          if (value === 'jobTitle') {
    
            headersTemp[value] = 'Job title'
          }
    
          else if (value === 'city') {
            headersTemp[value] = 'Città lavoro'
          }
    
          else if (value === 'maxRadius') {
            headersTemp[value] ='Distanza massima'
          }
    
          else if (value === 'skills') {
            headersTemp[value] ='Skill'
          }
    
          else if (value === 'education') {
            headersTemp[value] ='Education'
          }
    
          else if (value === 'language') {
            headersTemp[value] ='Lingue'
          }
    
          else if (value === 'license') {
            headersTemp[value] ='Patenti'
          }
        })
        setHeadersNew(headersTemp)
        fetch(`/api/realtime/embeddingsearch?username=${accounts[0]['username']}`, {
          method: 'POST',
          headers: { 'Accept': 'application/json',
                    "Content-Type": "application/json"},
          body: JSON.stringify(props.dataSearch)})
          .then(response => response.json())
          .then(function(data){ 
            setCandidates(data) 
            if (data.length === 0) {
              setTimeE(true)
            }

            else {
              for (let i=0; i<=Math.min(4,data.length-1); i++) {
                fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                  method: 'POST',
                  headers: { 'Accept': 'application/json',
                            "Content-Type": "application/json"},
                  body: JSON.stringify({cv:"",jobDescription:"",motivo_match:data[i].Motivo_Match})})
                  .then(response => response.json())
                  .then(function(result) {
                    setTempString(result['spiegazione'])
                    expTemp[data[i].associateid] = result['spiegazione']
                    setExplain(expTemp)
                  } )
                  .catch(error => console.error('Error fetching data:', error));
              }
            }
          })
          .catch(error => console.error('Error fetching data:', error));

      }
    
      const generateQuestion = () => {
        console.log(props.dataSearch["jobTitle"])
        setIqg(true);
      }
    
      const getDetails = (fiscale:any) => {
        setCodFisc(fiscale);
        setDetails(true);
      }

      const toggleDropdown = async(id:any,candidate:any) => {
        setDropdown((prev) => {
          if (prev === id) {
            return null
          }
          else {
            return id
          }
        });

        if (!(candidate.associateid in explain)) {
          let expTemp:any = explain
          fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
            method: 'POST',
            headers: { 'Accept': 'application/json',
                      "Content-Type": "application/json"},
            body: JSON.stringify({cv:"",jobDescription:"",motivo_match:candidate.Motivo_Match})})
            .then(response => response.json())
            .then(data => setTempString(data['spiegazione']))
            .catch(error => console.error('Error fetching data:', error));
            expTemp[candidate.associateid] = tempString
          setExplain(expTemp)
        }
    
      };

      const toggleCheck = (id:any) => {
        let partial = props.checkList;
        if (props.checkList.includes(id)) {
          partial = partial.filter(function(e:any) { return e !== id })
          props.setCheckList(partial)
          
        }
        else {
          props.setCheckList([...props.checkList,id])
        }
    
        console.log(props.checkList)
      };

      const generateEmailToCandidate = (candidate:any,ds:any) => {
        setMatchDetails({
                        'professional_category':ds['jobTitle'],
                        'Motivo_Match':candidate['Motivo_Match'],
                        'Distanza_km':candidate['distance'],
                        'work_city':ds['city'],
        });
        setCandidateId(candidate.associateid)
        setEmailC(true);
      }
    
      const generateEmail = (candidate: any) => {
        setCandidateDetails({'Motivo_Match':candidate['Motivo_Match'],
                             'Distanza_km':candidate['distance']});
        setCandidateId(candidate.associateid)
        setEmail(true);
      }

      useEffect(() => {
        handleSearchCandidate();
      }, [])

    return (
    <div>
      {(emailC)?
        <EmailJob email={emailC} setEmail={setEmailC} job={matchDetails} candidateId={candidateId} jobId={"realtime"}></EmailJob>
      :
      <div>
      {(email) ?
      <div>
        <EmailCandidate email={email} setEmail={setEmail} candidate={candidateDetails} candidateId={candidateId} jobId={"realtime"} category={props.dataSearch['jobTitle']} workCity={props.dataSearch["city"]}></EmailCandidate>
      </div>
      :
      <div>
        {(isDetails) 
        ?
          <div>
            <div>
              <CardDetailsRealTime cvs={cvs} setCvs={setCvs} codFisc={codFisc} setDetails={setDetails} urlPDF={urlPDF} setUrlPDF={setUrlPDF} isAI = {isAI} setIsAI = {setIsAI}></CardDetailsRealTime>
            </div>
              <div style={{textAlign:"center"}}>
              <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
              </div>
            </div>
        : 
        <div>
          {(iqg) ?
          <div>
            <IQG category={props.dataSearch["jobTitle"]} iqg={iqg} setIqg={setIqg} candidateId={candidateId} jobId={"realtime"}></IQG>    
          </div>
          :
          <div>
            <div>
            <div className='home-btn-slot-5'>
              <i className='bx bx-home bx-sm bx-border' id='home-btn' onClick={getBack}>
                <span className='home-btn-text-5'>
                Torna alla schermata Home
                </span>
              </i>
            </div>
          </div>
          {(candidates.length > 0 || timeE)? 
            <div className="row-main">
              <div className="column-table-direct">
                {props.headers.filter((item:any) => {
                let toExclude = [
                ]
                if (props.dataSearch[item].length == 0) {
                  toExclude.push(item)
                }
                if (!toExclude
              .includes(item)
            ) return item
          
            }).map((header:any) => (
                <div className="inline-div-super-dir">
                    <h2 className="test-h3">{headersNew[header]}</h2>
                  <h5>{props.dataSearch[header]}</h5>
                </div>
                ))}
              </div>
              <div className="sovra-container-direct">
              {(timeE &&
                  <div className="no-result">
                    <strong><p>Nessun risultato trovato</p></strong>
                  </div>
                  )}
                  {candidates.map((candidate: any) => (
                    <div key={candidate.associateid} className="candidate-card-dir">
                      <input type="checkbox" className="check-with-label" id="idinput" onClick={() => toggleCheck(candidate.associateid)} checked={props.checkList.includes(candidate.associateid)}></input>
                      <label className="label-for-check">Visualizzato</label>
                      <div className="sub-container-lr">
                      <div className="section-infos-right-dir">
                          <p><strong>Nome: </strong>{candidate.name} {candidate.surname}</p>
                          <p><strong>CF: </strong>{candidate.associateid}</p>
                          <p><strong>Esperienza: </strong>{candidate.experience}</p>
                          <p><strong>Distanza: Domicilio</strong><strong style={{ color: '#DA291C' }}> {candidate.Comune_Domicilio} </strong>- <strong>Lavoro</strong><strong style={{ color: '#DA291C' }}> {props.dataSearch['city']} </strong>pari a {Math.round(Number(candidate.distance))} KM</p>
                          <p><strong>Max Step Pipeline:</strong> {candidate.max_step_pipeline_overall}</p>
                          <p><strong>Collaboratore in disponibilità:</strong> {candidate.sl_bench}</p>
                        </div>
                        <div className="section-infos-left">
                        <p
                            className= {
                              candidate.Stato_Candidato === "Certificato" 
                                                        ? "green-tag" 
                                                        : candidate.Stato_Candidato === "Da valutare"
                                                        ? "orange-tag"
                                                        : candidate.Stato_Candidato === "Non idoneo"
                                                        ? "red-tag"
                                                        : ""
                            }
                            >
                            {candidate.Stato_Candidato}
                          </p>
                        </div>
                      </div>
                      <div id="dropdown-full">
                        <div 
                          key={candidate.associateid}
                          className={`dropdown-slot ${dropdown === candidate.associateid ? 'dropdown-slot-active' : ''}`} 
                          onClick={() => toggleDropdown(candidate.associateid,candidate)}>
                          <i 
                            className={`bx bx-sm bx-chevron-right ${dropdown === candidate.associateid ? 'arrow-icon-rotated' : ''}`} 
                            id='arrow-icon'
                            style={{
                              transform: dropdown === candidate.associateid ? 'rotate(90deg)' : 'rotate(0deg)',
                              transition: 'transform 1s ease'
                            }}
                          ></i>

                            <span className={`dropdown-text ${dropdown === candidate.associateid ? 'dropdown-text-hidden': 'dropdown-text'}`}>
                              {dropdown === candidate.associateid? "Torna indietro": "Info Match"}
                            </span>

                        </div>
                        <div className={`match-reason ${dropdown === candidate.associateid ? '' : 'match-reason-hidden'}`}>
                          <span className={`match-reason ${dropdown === candidate.associateid ? '' : 'match-reason-hidden'}`}>
                            {(explain[candidate.associateid] !== undefined) ? "" : <LoaderComp></LoaderComp>}
                          </span>
                          {dropdown === candidate.associateid? explain[candidate.associateid]: ""}
                        </div>
                        <div className="row-button">
                          <button className="cta-red-mat" onClick={() => getDetails(candidate.associateid)}>
                            {"Dettagli Candidato"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmailToCandidate(candidate,props.dataSearch)}>
                            {"Email Candidato"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateQuestion()}>
                            {"Genera Domande"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmail(candidate)}>
                            {"Email Cliente"}
                          </button>
                        </div>
                      </div>
                  </div>
                ))}
            </div>
            </div>
          : 
          <div className="div-center">
            <div>
              <h3> Ricerca candidati in corso</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
              <LoaderComp></LoaderComp>
            </div >
          </div>}
          </div>
          }
          </div>
          }
          </div>
          }
          </div>
          }
      </div>)
}

export default RealTimeMatch

